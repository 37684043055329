<template>
  <div>
    <v-dialog v-model="dialog" persistent
              scrollable
              width="1100">
      <v-card :loading="loading">
        <v-card-title>
          Liste POS
          <v-spacer/>
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text>

          <div class="d-flex justify-space-between my-4">
            <div :style="{width : '250px'}">
              <v-select
                      v-model="status"
                      :items="statuses"
                      chips
                      class="ml-1"
                      dense
                      filled
                      hide-details
                      item-text="text"
                      item-value="value"
                      placeholder="Statut"
                      rounded
                      small-chips>
                <template v-slot:selection="data">
                  <v-chip :color="data.item.color" dark small>
                    {{ data.item.text }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar size="10">
                    <v-icon :color="data.item.color">mdi-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.text"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </div>
            <div class="mt-1 ml-1">
              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="refresh" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Réinitialiser</span>
              </v-tooltip>
            </div>
            <v-spacer/>
            <v-btn color="primary" text
                   @click="exportExcelAffectedSalepoints(id)">
              <v-icon left>mdi-cloud-download-outline</v-icon>
              Exporter
            </v-btn>
          </div>

          <v-skeleton-loader v-if="loading" type="table"/>

          <div v-if="!loading && salepoints.length > 0">

            <v-simple-table class="table-border">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Photo</th>
                  <th id="key_26" class="text-left">{{ keywords.key_26 }}</th>
                  <th id="key_27" class="text-left">{{ keywords.key_27 }}</th>
                  <th id="key_1" class="text-left">{{ keywords.key_1 }}</th>
                  <th id="key_28" class="text-left">{{ keywords.key_28 }}</th>
                  <th class="text-left">Téléphone-APP</th>
                  <th class="text-left">
                    <span id="key_3">{{ keywords.key_3 }}</span>
                  </th>
                  <th class="text-left">
                    <span id="key_2">{{ keywords.key_2 }}</span>
                  </th>
                  <th class="text-left">
                    <span id="key_40">{{ keywords.key_40 }}</span>
                  </th>
                  <th class="text-center">Date</th>
                  <th class="text-center">Statut</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in salepoints" :key="item.id">
                  <td>
                    <v-avatar size="38">
                      <v-img v-if="item.salepoint.salepoint_account && item.salepoint.salepoint_account.photo"
                             :src="FILE_URL + item.salepoint.salepoint_account.photo"></v-img>
                      <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                    </v-avatar>
                  </td>
                  <td>{{ item.salepoint.code }}</td>
                  <td>{{ item.salepoint.name }}</td>
                  <td>{{ item.salepoint.type }}</td>
                  <td>{{ item.salepoint.phone }}</td>
                  <td>
                   <span v-if="item.salepoint.salepoint_account && item.salepoint.salepoint_account.salepoint_phone"
                         class="info--text">
                      {{ item.salepoint.salepoint_account.salepoint_phone }}
                      </span>
                    <span v-else>-</span>
                  </td>
                  <td>{{ item.salepoint.city }}</td>
                  <td>{{ item.salepoint.region }}</td>
                  <td>{{ item.salepoint.address || '-' }}</td>
                  <td>{{ item.syncronized_at || '-' }}</td>
                  <td class="text-center">
                    <v-chip :color="setStatusColor(item.status)"
                            dark
                            small>
                      <v-icon left small v-html="setStatus(item.status)['icon']"></v-icon>
                      {{ setStatus(item.status)['value'] }}
                    </v-chip>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-card-actions>
              <div>
                <v-select v-model="per_page"
                          :items="[10,20,50,100,200,300,400,500]"
                          :style="{width: '120px'}"
                          dense
                          hide-details
                          label="Ligne par page"
                          outlined></v-select>
              </div>
              <v-spacer/>
              <v-pagination v-if="total>per_page"
                            v-model="pagination.current"
                            :length="pagination.total"
                            circle
                            total-visible="6"
                            @input="onPageChange"
              ></v-pagination>
            </v-card-actions>

          </div>

          <NoResult
                  v-if="!loading && salepoints.length === 0"
                  class="text-center"/>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResult from "../../../../components/NoResult";

export default {
   components: {NoResult},
   data() {
      return {
         FILE_URL: process.env.VUE_APP_FILE_URL,
         keywords: this.$store.state.keyword,

         dialog: false,
         loading: false,

         status: 'all',
         statuses: [
            {'color': 'blue', 'value': 'all', 'text': 'Tous'},
            {'color': 'success', 'value': 'okResponse', 'text': 'POS qui a répondus'},
            {'color': 'orange', 'value': 'noResponse', 'text': 'POS qui n\'a pas répondu'},
         ],

         taskId: null,
         salepoints: [],
         total: 0,
         per_page: 10,
         pagination: {
            current: 1,
            total: 0
         },
      }
   },
   methods: {
      open(taskId) {
         this.dialog = true
         this.taskId = taskId
         this.getAffectedSalepoints()
      },
      close() {
         this.dialog = false
      },

      setStatusColor(status) {
         switch (status) {
            case 'sent' :
               return 'orange';
         }
         return 'success';
      },
      setStatus(status) {
         switch (status) {
            case 'sent' :
               return {
                  'icon': 'mdi-timer-sand',
                  'value': 'En attente',
               };
         }
         return {
            'icon': 'mdi-check',
            'value': 'Répondu',
         };
      },

      getAffectedSalepoints(current_page, per_page) {
         this.pagination.current = current_page ? current_page : this.pagination.current
         this.per_page = per_page ? per_page : this.per_page
         this.loading = true
         this.$Progress.start()
         HTTP.get('/campaigns/task/' + this.taskId + '/affected-salepoints?page=' + this.pagination.current, {
            params: {
               per_page: this.per_page,
               status: this.status,
            }
         }).then((res) => {
            this.salepoints = res.data.data.data
            this.pagination.current = res.data.data.current_page;
            this.pagination.total = res.data.data.last_page;
            this.total = res.data.data.total;
            this.loading = false
            this.$Progress.finish()
         }).catch(err => {
            this.loading = false
            this.$Progress.fail()
            console.log(err)
         })
      },
      onPageChange() {
         this.getAffectedSalepoints()
      },
      refresh() {
         this.status = 'all'
         this.getAffectedSalepoints()
      },
      exportExcelAffectedSalepoints() {
         let baseUrl = process.env.VUE_APP_FILE_URL
         let token = this.$store.state.user.token
         let userId = this.$store.state.user.id
         let DownloadURL = baseUrl + '/export/excel/affected-salepoints?token=' + token + '&id=' + userId + '&task_id=' + this.taskId;
         window.open(DownloadURL, '_blanc')
      },
   },
   watch: {
      keyword() {
         this.getAffectedSalepoints();
      },
      per_page() {
         this.pagination.current = 1
         this.getAffectedSalepoints();
      },
      status() {
         this.getAffectedSalepoints();
      },
   }
}
</script>

<style scoped>

</style>