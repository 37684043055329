<template>
  <div>
    <v-dialog v-model="dialog"
              max-width="1100"
              @click:outside="close"
              scrollable>
      <v-card :loading="loading" :disabled="loading">
        <v-card-title>
          Tâches
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text>

          <v-row class="my-2">
            <v-col cols="6">
              <v-text-field v-model="keyword" dense filled rounded
                            append-icon="mdi-magnify"
                            clearable
                            placeholder="Recherche..."
                            single-line hide-details></v-text-field>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn color="blue"
                     depressed dark
                     class="rounded-lg"
                     :to="'/campaigns/'+campaign_id+'/add-tasks'">
                <v-icon left>mdi-plus</v-icon>
                Nouvelles tâches
              </v-btn>
            </v-col>
          </v-row>

          <v-simple-table class="table-border">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Statut
                </th>
                <th class="text-left">
                  Nom (FR)
                </th>
                <th class="text-left">
                  Nom (AR)
                </th>
                <th class="text-left">
                  Planification
                </th>
                <th class="text-left">
                  Progression
                </th>
                <th class="text-center">
                  Jours restants
                </th>
                <th class="text-right">
                  Actions
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in filterByTerm" :key="i">
                <td>
                  <v-switch v-model="item.status" readonly color="blue" hide-details
                            class="ma-0"
                            @click="changeStatus(item.id,item.status)"></v-switch>
                </td>
                <td>{{ item.name }}</td>
                <td>
                  <span class="ar">{{ item.name_ar }}</span>
                </td>
                <td>
                  {{ item.start_date }} ~ {{ item.end_date }}
                </td>
                <td>
                  <div v-if="item.sp_progress" class="my-2">

                    <div class="d-flex justify-space-between">
                      <strong>{{ item.sp_progress.total }}</strong>
                      <v-spacer/>
                      <strong>{{ item.sp_progress.progress }}%</strong>
                    </div>

                    <v-progress-linear :value="item.sp_progress.progress"
                                       striped
                                       rounded
                                       class="w-150"
                                       :color="setColor(item.sp_progress.progress)" height="5"/>

                    <div class="d-flex justify-space-between mt-2">

                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small fab icon class="v-btn--active"
                                 v-bind="attrs" v-on="on"
                                 @click="$refs.affectedSalepointsDialog.open(item.id)">
                            <v-icon>mdi-table</v-icon>
                          </v-btn>
                        </template>
                        <span>Voir le liste</span>
                      </v-tooltip>

                      <v-spacer/>

                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small fab icon class="v-btn--active"
                                 @click="exportExcelAffectedSalepoints(item.id)"
                                 v-bind="attrs" v-on="on">
                            <v-icon>mdi-download-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Télécharger la liste en excel</span>
                      </v-tooltip>
                    </div>
                  </div>


                </td>
                <td class="text-center">
                  <div v-if="item.left_days">
                    <v-chip small :color="item.left_days.color" dark outlined>
                      {{ item.left_days.value }}
                    </v-chip>
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            @click="showTask(item)"
                            icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Plus de détails</span>
                    </v-tooltip>

                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            :to="'/campaigns/task/'+item.id+'/duplicate'"
                            icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-content-duplicate</v-icon>
                        </v-btn>
                      </template>
                      <span>Dupliquer</span>
                    </v-tooltip>

                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            :to="'/campaigns/task/'+item.id+'/edit'"
                            icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Modifier</span>
                    </v-tooltip>

                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            @click="$refs.editDateDialog.open(item)"
                            icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-update</v-icon>
                        </v-btn>
                      </template>
                      <span>Prolonger les dates</span>
                    </v-tooltip>

                  </div>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <CalendarTaskDialog :dialog.sync="calendarTaskDialog"
                        :task.sync="task"
                        ref="calendarTaskDialog"/>

    <EditDateDialog ref="editDateDialog" @refresh="refresh"/>

    <AffectedSalepointsDialog ref="affectedSalepointsDialog"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import CalendarTaskDialog from "./CalendarTaskDialog";
import EditDateDialog from "../../components/EditDateDialog";
import AffectedSalepointsDialog from "./AffectedSalepointsDialog";

export default {
  components: {AffectedSalepointsDialog, CalendarTaskDialog, EditDateDialog},
  props: ['dialog', 'tasks', 'campaign_id'],
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      loading: false,
      menu: false,
      keyword: '',
      calendarTaskDialog: false,
      task: ''
    }
  },
  methods: {
    setColor(el) {
      if (el <= 30) {
        return 'red'
      }
      if (el > 30 && el <= 60) {
        return 'yellow'
      }
      if (el > 60 && el <= 90) {
        return 'primary'
      }
      if (el > 90 && el <= 100) {
        return 'success'
      }
    },
    showTask(el) {
      this.calendarTaskDialog = true
      this.$refs.calendarTaskDialog.showTask(el.id)
    },
    close() {
      this.$emit('update:dialog', false)
    },
    changeStatus(id, status) {
      this.$Progress.start()
      this.loading = true
      let data = {
        id: id,
        status: !status,
      }
      HTTP.post('/campaigns/task/change-status', data).then(() => {
        const i = this.tasks.findIndex(item => item.id === id)
        this.tasks[i].status = !status
        this.loading = false
        this.$Progress.finish()
        this.$successMessage = 'Status changé avec succes !'
      }).catch(err => {
        console.log(err)
        this.$Progress.fail()
        this.loading = false
      })
    },
    refresh() {
      this.close()
      this.$emit('refresh')
    },
    exportExcelAffectedSalepoints(taskId) {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.token
      let userId = this.$store.state.user.id
      let DownloadURL = baseUrl + '/export/excel/affected-salepoints?token=' + token + '&id=' + userId + '&task_id=' + taskId;
      window.open(DownloadURL, '_blanc')
    },
  },
  computed: {
    filterByTerm() {
      return this.tasks.filter(task => {
        return task.name.toLowerCase().includes(this.keyword.toLowerCase());
      });
    }
  }
}
</script>

<style scoped>

</style>