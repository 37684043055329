<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay"
                 opacity="0">
        <v-progress-circular
                color="primary"
                indeterminate
                size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col cols="12">
          <v-card :disabled="overlay" :loading="overlay" class="rounded-lg shadow">
            <v-card-title>

              <div>
                <v-text-field v-model="keyword"
                              clearable
                              append-icon="mdi-magnify"
                              class="w-300"
                              dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded single-line></v-text-field>
              </div>

              <v-spacer/>

              <v-btn class="text-none" disabled text>
                {{ total }} Élément(s)
              </v-btn>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="refresh" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="drawer = true"
                         v-on="on">
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                <span>Filtrer</span>
              </v-tooltip>

              <v-btn class="ml-1" color="blue" dark depressed fab small
                     @click="exportAll">
                <v-icon>mdi-download-outline</v-icon>
              </v-btn>

              <v-btn class="ml-1" color="blue" dark depressed fab small
                     @click="[$func.resetStore(),
                     $router.push('/campaigns/stepper/info&planning')]">
                <v-icon>mdi-plus</v-icon>
              </v-btn>

            </v-card-title>
            <v-skeleton-loader v-if="loading" type="table"/>
            <v-card-text v-if="!loading && campaigns.length === 0" class="text-center">
              <NoResult/>
            </v-card-text>
            <div v-if="!loading && campaigns.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Nom(FR)</th>
                    <th>Nom(AR)</th>
                    <th>Planification</th>
                    <th>Tâches</th>
                    <th>Créer le</th>
                    <th class="text-center">Analyses</th>
                    <th class="text-center">Calendrier</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in campaigns" :key="item.id">

                    <td>
                      <div class="pa-2">
                        <v-img v-if="item.photo"
                               :src="FILE_URL + item.photo"
                               class="rounded zoom-pointer"
                               height="45"
                               width="45" @click="[image = [{src: FILE_URL + item.photo}],visible = true]"/>

                        <v-img v-else :src="require('@/assets/no-image.png')"
                               class="rounded img-border"
                               height="45" width="45"/>
                      </div>
                    </td>

                    <td>{{ item.name }}</td>

                    <td class="ar rtl">{{ item.name_ar }}</td>

                    <td>
                      {{ item.start_date }} ~ {{ item.end_date }}
                    </td>

                    <td>
                      <v-chip color="blue" dark small @click="[taskDialog=true,tasks = item.tasks,campaign_id=item.id]">
                        <v-icon left small>mdi-file-document-outline</v-icon>
                        {{ item.tasks.length }}
                      </v-chip>
                    </td>

                    <td>
                      {{ item.created_at }}
                    </td>

                    <td class="text-center">
                      <v-btn :to="'/campaigns/'+item.id+'/analytics'" class="text-none"
                             color="primary"
                             text>
                        <v-icon left>mdi-chart-pie</v-icon>
                        Analyses
                      </v-btn>
                    </td>

                    <td class="text-center">
                      <v-btn :to="'/campaigns/'+item.id+'/calendar'" class="v-btn--active"
                             icon>
                        <v-icon>mdi-calendar-range</v-icon>
                      </v-btn>
                    </td>

                    <td>
                      <v-menu bottom right transition="slide-x-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item @click="editItem(item)">
                            <v-list-item-title>
                              <v-icon dense left>mdi-pencil-outline</v-icon>
                              Modifier les information
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteItem(item.id)">
                            <v-list-item-title>
                              <v-icon dense left>mdi-trash-can-outline</v-icon>
                              Supprimer
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>


              <v-divider v-if="total > 10"/>

              <v-card-actions v-if="total > 10">

                <div>
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                </div>

                <v-spacer/>
                <v-pagination v-if="total > 10"
                              v-model="pagination.current"
                              :length="pagination.total"
                              circle
                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>

            </div>
          </v-card>
        </v-col>
      </v-row>

      <!--Dialogs && Drawer-->

      <TasksDialog
              :campaign_id.sync="campaign_id"
              :dialog.sync="taskDialog"
              :tasks.sync="tasks"
              @refresh="getCampaigns"/>

      <vue-easy-lightbox :imgs="image"
                         :index="index"
                         :visible="visible"
                         escDisabled
                         moveDisabled
                         @hide="visible = false"></vue-easy-lightbox>

      <UpdateInfoDialog ref="updateInfoDialog"
                        :dialog.sync="updateInfoDialog"
                        @get-campaigns="getCampaigns"/>

      <v-navigation-drawer
              v-model="drawer"
              :permanent="drawer" app
              clipped
              floating
              right
              temporary
              width="400">
        <v-list-item>
          <v-list-item-avatar color="blue">
            <v-icon dark>mdi-tune</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Filtrages</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="drawer = false">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider/>
        <FilterDrawer ref="FilterDrawer" @filter="[getCampaigns($event),drawer=false]"/>
      </v-navigation-drawer>

      <!--Dialogs && Drawer-->

    </v-container>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import TasksDialog from "./Dialogs/TasksDialog";
import NoResult from "../../../components/NoResult";
import UpdateInfoDialog from "./Dialogs/UpdateInfoDialog";
import FilterDrawer from "./FilterDrawer";

export default {
    components: {UpdateInfoDialog, NoResult, TasksDialog, FilterDrawer},
    data() {
        return {
            overlay: false,
            loading: false,
            drawer: false,
            updateInfoDialog: false,
            id: '',
            campaigns: [],
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },
            keyword: '',
            filter: {},
            //Tasks dialog
            campaign_id: false,
            taskDialog: false,
            tasks: [],
            //Show image
            image: '',
            visible: false,
            index: 0,
            FILE_URL: process.env.VUE_APP_FILE_URL
        }
    },
    methods: {
        editItem(item) {
            this.updateInfoDialog = true
            this.$refs.updateInfoDialog.edit(item)
        },
        deleteItem(id) {
            this.id = id
            this.$confirm_dialog = true
        },
        getCampaigns(filter) {
            this.loading = true
            this.$Progress.start()
            this.filter = filter ? filter : this.filter
            HTTP.get('/campaigns?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    filter: this.filter,
                    per_page: this.per_page,
                }
            }).then((res) => {
                this.loading = false
                this.$Progress.finish()
                this.campaigns = res.data.data.data;
                this.pagination.current = res.data.data.current_page;
                this.pagination.total = res.data.data.last_page;
                this.total = res.data.data.total;
                this.refreshStore()
            }).catch(err => {
                this.$Progress.fail()
                this.loading = false
                console.log(err)
            })
        },
        onPageChange() {
            this.getCampaigns();
        },
        refresh() {
            this.filter = {}
            this.getCampaigns()
        },
        refreshStore() {
            HTTP.get('/campaigns/all').then(res => {
                this.$store.dispatch('campaigns', res.data.data)
            }).catch(err => {
                console.log(err)
            })
        },
        exportAll() {
            let baseUrl = process.env.VUE_APP_FILE_URL
            let token = this.$store.state.user.token
            let userId = this.$store.state.user.id
            let DownloadURL = baseUrl + '/export/excel/campaigns?token=' + token + '&id=' + userId;
            window.open(DownloadURL, '_blanc')
        },
        handleDelete() {
            this.$is_confirm = false
            this.overlay = true
            this.$Progress.start()
            HTTP.delete('/campaigns/delete/' + this.id).then(() => {
                let i = this.campaigns.findIndex(item => item.id === this.id)
                this.$delete(this.campaigns, i)
                this.$successMessage = 'Cette campagne a été supprimée avec succès'
                this.overlay = false
                this.$Progress.finish()
                this.refreshStore()
            }).catch(err => {
                this.overlay = false
                this.$Progress.fail()
                console.log(err)
            })
        }
    },
    created() {
        this.getCampaigns()
    },
    watch: {
        '$is_confirm': function (el) {
            if (el) {
                this.handleDelete()
            }
        },
        keyword() {
            this.getCampaigns();
        },
        per_page() {
            this.pagination.current = 1
            this.getCampaigns();
        },
    },
}
</script>

<style scoped>

</style>