<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" @click:outside="close">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title>
          Modifier les information
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-4">
          <form enctype="multipart/form-data" @submit.prevent="save">

            <v-row>
              <v-col>
                <v-text-field v-model.trim="form.name" label="Nom *"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                        v-model.trim="form.name_ar"
                        class="ar rtl"
                        label="الإ سم *"></v-text-field>
              </v-col>
            </v-row>

            <v-file-input
                    ref="photo"
                    :clearable="false"
                    accept="image/png,image/jpeg" append-icon="mdi-image-outline" chips
                    label="Photo"
                    show-size
                    small-chips
                    @change="uploadPhoto($event)"></v-file-input>

            <v-textarea v-model.trim="form.description" label="Description"
                        rows="2"></v-textarea>

            <v-textarea v-model.trim="form.description_ar"
                        class="ar rtl"
                        label="* الوصف"
                        rows="2"></v-textarea>


            <div class="d-flex justify-space-between mt-3">
              <v-spacer/>
              <v-btn :loading="loading" class="rounded-lg" color="primary" depressed
                     type="submit">
                <v-icon left>mdi-content-save</v-icon>
                Enregistrer
              </v-btn>
            </div>

          </form>
        </v-card-text>
      </v-card>

      <vue-easy-lightbox :imgs="image"
                         :index="index"
                         :visible="visible"
                         escDisabled
                         moveDisabled
                         @hide="visible = false"></vue-easy-lightbox>

    </v-dialog>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
    props: ['dialog'],
    data() {
        return {
            loading: false,
            form: {
                id: '',
                name: '',
                name_ar: '',
                description: '',
                description_ar: '',
                photo: '',
            },
            errors: {},
            //Show image
            image: '',
            visible: false,
            index: 0,
            FILE_URL: process.env.VUE_APP_FILE_URL,
        }
    },
    methods: {
        close() {
            this.$emit('update:dialog', false)
        },
        uploadPhoto(file) {
            this.form.photo = file
        },
        edit(item) {
            this.form.id = item.id
            this.form.name = item.name || ''
            this.form.name_ar = item.name_ar || ''
            this.form.description = item.description || ''
            this.form.description_ar = item.description_ar || ''
            this.form.photo = ''
            let _vm = this
            setTimeout(function () {
                _vm.$refs.photo.reset()
            }, 200)
            this.errors = {}
        },
        save() {
            this.loading = true
            this.$Progress.start()
            const url = '/campaigns/update-info'
            let formData = new FormData();
            Object.keys(this.form).forEach((key) => {
                formData.append(key, this.form[key])
            })
            HTTP.post(url, formData).then(() => {
                this.$successMessage = 'Cette campagne a été enregistré avec succès'
                this.loading = false
                this.$Progress.finish()
                this.$emit('get-campaigns')
                this.close()
                this.refreshStore()
            }).catch(err => {
                this.loading = false
                this.$Progress.fail()
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
        refreshStore() {
            HTTP.get('/campaigns/without-pagination').then(res => {
                this.$store.dispatch('campaigns', res.data.data)
            }).catch(err => {
                console.log(err)
            })
        }
    },
    computed: {
        campaigns() {
            return this.$store.state.campaigns
        }
    }
}
</script>

<style scoped>

</style>